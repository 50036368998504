import React, { useEffect } from "react";


const ProjectsMap = () => {

  useEffect(() => {
    const mapStyles = [
      {
        "featureType": "all",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#ffdfc0"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#dddddd"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      }
    ]
    window.initMap = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: -29.66511435545392, lng: 121.0872805715238 },
        zoom: 5,
        styles: mapStyles,
      });

      const locations = [
        { lat: -29.66511435545392, lng: 121.0872805715238, label:"Granny Venn Mine" },
        { lat: -29.74311084767141, lng: 121.0722063323793, label: "Blackjack Laterite" },
        { lat: -29.68320365784075, lng: 121.0168800899822, label: "Selkirk Project" },
        { lat: -31.94505745899852, lng: 122.1501716318766, label: "Jeffrey's Find" },
        { lat: -30.0756745539999, lng: 122.729697672, label: "Trouser Legs" },
      ];
      locations.forEach(({lat, lng, label}) => {
        new window.google.maps.Marker({
          position: {lat:lat, lng:lng},
          map: map,
          label:label,
        });
      });
    };
  }, []);

  return <div id="map" style={{ height: "500px", width: "100%" }} />;
}

export default ProjectsMap