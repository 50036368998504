import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


const ProjectCard = ({ projectName, projectImg, slug, expected, result }) => (
<div className="card w-100 glass bg-orange-900 my-6 md:my-0 ">
    <figure className="my-0 justify-start h-44"><GatsbyImage image={projectImg} alt={`${projectName} overhead shot`} /></figure>
    <div className="card-body bg-orange-800 justify-end">
      <h2 className="card-title text-capitalize text-3xl font-bold py-1 m-0 text-white">{projectName}</h2>
        <Link className="my-1" to={slug}>
          <button class="rounded-full bg-orange-200 text-black font-bold w-40 py-2 shadow-lg hover:bg-orange-900">
            Find out more
          </button>
        </Link>
    </div>
  </div>
)


export default ProjectCard
