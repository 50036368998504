import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ProjectSlider from '../components/BootstrapSlider';
import VideoFullRow from '../components/VideoFullRow';
import poster from "./../images/poster1.webp"
import VideoHero from "../components/VideoHero";
import ProjectsMap from "../components/ProjectsMap";
const IndexPage = () => {

return (
  <Layout>
  <div className="overflow-x-hidden">
      <section className="hero__home justify-center relative flex items-center flex-col">
        <div className="opacity-50 bg-black absolute left-0 right-0 top-0 bottom-0 z-0"></div>
        <div className="container z-10 mx-auto items-center flex flex-col text-center h-full">
          <h1 className="text-5xl font-bold text-center opacity-90 p-2 rounded-md text-white hidden">BML Ventures</h1>
          <h2 className="text-xl md:text-3xl font-bold text-center opacity-90 p-2 rounded-md text-white">BML Ventures is a WA Goldfields based company that funds, develops, and operates open pit gold mines with a strong technical focus and relationships with local toll treatment operators to maximise profitability from small to medium-sized mining projects.</h2>
        </div>
        <VideoHero src="https://ordinaryseo.com.au/wp-content/uploads/2023/06/bml-bg-video-2.mp4" poster={poster} />
      </section>
      <div className="home__wrapper bg-orange-200">
        <section className="stripe-bg">
          <div className="container mx-auto flex items-center text-center justify-center">
            <div className="columns-1 text-center"></div>
          </div>
        </section>
        <section className="Home__map mx-auto">
          <ProjectsMap />
         </section>
        <section className="py-12">
          <VideoFullRow />
        </section>
        <section className="slider pb-16">
          <ProjectSlider />
        </section>
      </div>
    </div>
  </Layout>
)}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => 
<>
  <Seo title="Home" />
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_6GPWbeg8pzWhEf_Mr098T7xanE5177E&callback=initMap" async="true" />
</>

export default IndexPage
