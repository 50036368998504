import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectCard from "../components/ProjectCard"

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
  };
  const projects = useStaticQuery(graphql`
    query ProjectsQuery {
      wpPage(slug: {eq: "projects"}) {
        projects {
          items {
            name
            link
            image {
              gatsbyImage(width: 600)
            }
          }
        }
      }
    }
  `)
  return (
    <div className="mx-auto py-5">
      <div className="container">
      <h2 className="font-sans font-bold py-6">Our Projects</h2>
      <Slider {...settings}>
        {projects.wpPage.projects.items.map( project => 
          <div key={project.name}>
            <div className="mx-2">
              <ProjectCard
              projectName={project.name}
              slug={project.link}
              projectImg={project.image.gatsbyImage}/>
            </div>
          </div>
        )}
      </Slider>
      </div>
    </div>
  );
}
