import React from "react";
import VideoBg from "reactjs-videobg"

const VideoHero = props => {
  const source = props.src
  const poster = props.poster

  return (
    <>
      <VideoBg poster={poster}>
        <VideoBg.Source src={source} type="video/mp4" />
      </VideoBg>
    </>
  )
}
export default VideoHero