import * as React from "react"
import { Link } from "gatsby"

const VideoFullRow = ({ projectName, projectImg, slug }) => (
  <div className="flex flex-wrap">
    <div className="video-col">
    </div>
    <div className="content-col">
      <h2 className="font-sans text-4xl font-bold text-white">Our History</h2>
      <p className="font-sans text-xl font-normal text-white">
        BML Ventures has a strong background in mining, Andrew Bewsher from BMGS and William Llyod from IMS were separately engaged by Hawthorn Resources to assist in the management of the Trouser Legs mine operation in 2018.
        In 2020 BMGS and IMS collaborated again to successfully mine 25K of gold ore from the Blackjack project south of Menzies.<br/><br/>
        In 2021 BMLV was incorporated and their first project was the successful mining of approx. 100K tonnes of ore from the Granny Venn project for REZ to the north of Menzies.  
        BMLV have since signed on to evaluate and potentially mine the Jeffreys Find deposit (Auric mining) and the Selkirk Deposit (Kingswest Resources). 
        BML Ventures prioritises safety, sustainability, and excellence and aims to continue expanding and contributing to the mining industry's growth.
    </p>
      <Link to="/timeline" className="learn-more">Learn More</Link>
    </div>
  </div>
)


export default VideoFullRow
